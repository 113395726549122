import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Drawer, Layout, Menu } from 'antd';

import { useAppContext } from '@/context/appContext';
import logoIcon from '@/style/images/logo-icon.png';
import logoText from '@/style/images/logo-text.png';

import {
  MenuOutlined,
  SettingOutlined,
  DashboardOutlined,
  BankOutlined,
  ReadOutlined,
  ProjectFilled,
  ProjectOutlined,
  MonitorOutlined
} from '@ant-design/icons';
import { AdminPanelSettingsOutlined, Inventory2Outlined, TaskAltOutlined } from '@mui/icons-material';

import { useSelector, useDispatch } from 'react-redux';
import { crud } from '@/redux/crud/actions';
import { selectMenu } from '@/redux/crud/selectors';

const { Sider } = Layout;
const { SubMenu } = Menu;

export default function Navigation() {
  return (
    <>
      <div className="sidebar-wraper">
        <Sidebar collapsible={true} />
      </div>
      <MobileSidebar />
    </>
  );
}

function Sidebar({ collapsible }) {
  const { state: stateApp, appContextAction } = useAppContext();
  const { isNavMenuClose } = stateApp;
  const { navMenu } = appContextAction;
  const [showLogoApp, setLogoApp] = useState(isNavMenuClose);

  const dispatch = useDispatch();
  const { result: objMenu, isLoading: listIsLoading, } = useSelector(selectMenu);
  useEffect(() => {
    dispatch(crud.menu());
  }, []);
  useEffect(() => {
    if (isNavMenuClose) {
      setLogoApp(isNavMenuClose);
    }
    const timer = setTimeout(() => {
      if (!isNavMenuClose) {
        setLogoApp(isNavMenuClose);
      }
    }, 200);
    return () => clearTimeout(timer);
  }, [isNavMenuClose]);
  const onCollapse = () => {
    navMenu.collapse();
  };

  if (!objMenu) {
    return null;
  }

  return (
    <>
      <Sider collapsible={collapsible}
        collapsed={collapsible ? isNavMenuClose : collapsible}
        onCollapse={onCollapse} className="navigation">
          
        <div className="logo">
          <img
            src={logoIcon}
            alt="Logo"
          // style={{ margin: "0 auto 40px", display: "block" }}
          />

          {!showLogoApp && (
            <img src={logoText} alt="Logo" style={{ marginTop: '3px', marginLeft: '10px' }} />
          )}
        </div>{
          !listIsLoading &&
          <Menu mode="inline">
            <Menu.Item key={'Dashboard'} icon={<DashboardOutlined />}>
              <Link to={'/'} />
              Dashboard
            </Menu.Item>
            {objMenu?.Project?.isTableView ?
              <Menu.Item key={'Project'} icon={<ProjectOutlined />}>
                <Link to={'/project'} />
                Project
              </Menu.Item> : ''}

            <SubMenu key={'Project Management'} icon={<MonitorOutlined />} title={'Project Management'}>

              {objMenu?.Purchase?.isTableView ?
                <Menu.Item key={'Purchase'}>
                  <Link to={'/purchase_order'} />
                  Purchase
                </Menu.Item>
                : ''}
              {objMenu?.Subcontract?.isTableView ?
                <Menu.Item key={'SubContract'} >
                  <Link to={'/sub_contract'} />
                  SubContract
                </Menu.Item>
                : ''}

              {objMenu['Expense Entry']?.isTableView ?
                <Menu.Item key={'Expense'} >
                  <Link to={'/expense_entry'} />
                  Expense Entry
                </Menu.Item>
                : ''}

              {objMenu['Client Payment']?.isTableView ?
                <Menu.Item key={'clientPayment'} >
                  <Link to={'/client/payment'} />
                  Client Payment
                </Menu.Item>
                : ''}

            </SubMenu>

            <SubMenu key={'ProjectExecution'} icon={<ProjectFilled />} title={'Project Execution'}>
              {objMenu?.Estimantion?.isTableView ?
                <Menu.Item key={'Estimation'}>
                  <Link to={'/estimation'} />
                  Estimation
                </Menu.Item> : ""}

              {objMenu?.BOQ?.isTableView ?
                <Menu.Item key={'BOQs'}>
                  <Link to={'/boq'} />
                  BOQ
                </Menu.Item> : ""}


              {objMenu['Task Managemnet']?.isTableView ?

                <Menu.Item key={'Task Management'}>
                  <Link to={'/task_management'} />
                  Task Management/Scheduling
                </Menu.Item> : ""}

              {objMenu['Resourse Allocation']?.isTableView ?

                <Menu.Item key={'ResourceAllocation'}>
                  <Link to={'/resource_allocation'} />
                  Resources Allocation
                </Menu.Item> : ""}
            </SubMenu>


            <SubMenu key={'Accounts'} icon={<BankOutlined />} title={'Accounts'}>

              {
                objMenu?.Transactions?.isTableView ?
                  <Menu.Item key={'Transaction'}>
                    <Link to={'/transaction'} />
                    Transactions
                  </Menu.Item> : ''
              }
              {
                objMenu['Journal Entry']?.isTableView ?
                  <Menu.Item key={'JournalEntry'}>
                    <Link to={'/journalentry'} />
                    Journal Entry
                  </Menu.Item> : ''
              }
              {
                objMenu['Document No']?.isTableView ?
                  <Menu.Item key={'DocumentNo'}>
                    <Link to={'/document_no'} />
                    Document No
                  </Menu.Item> : ''
              }
            </SubMenu>

            <SubMenu key={'Reports'} icon={<ReadOutlined />} title={'Reports'}> 

            {objMenu?.Daybook?.isTableView?
                <Menu.Item key={'DayBook'}>
                  <Link to={'/day_book'} />
                  Day Book
                </Menu.Item> 
                :''}    

                {objMenu['Closing report']?.isTableView?
                <Menu.Item key={'ClosingReport'}>
                  <Link to={'/closing_rpt'} />
                  Closing Report
                </Menu.Item> 
                : ''}     

              <Menu.Item key={'Profit&loss'}>
                <Link to={'/profit_loss'} />
                Profit & loss
              </Menu.Item>
{
  objMenu['Balance Sheet']?.isTableView?
  <Menu.Item key={'BalanceSheet'}>
                  <Link to={'/balance_sheet'} />
                  Balance Sheet
                </Menu.Item> 

:''}
              <Menu.Item key={'CashFlow'}>
                <Link to={'/cash_flow'} />
                Cash Flow
              </Menu.Item>
            </SubMenu>

            <SubMenu key={'Inventory'} icon={<Inventory2Outlined />} title={'Inventory'}>
              <Menu.Item key={'StockItems'}>
                <Link to={'/stock_items'} />
                Stock Items
              </Menu.Item>
              <Menu.Item key={'StockTransfer'}>
                <Link to={'/stock_transfer'} />
                Stock Transfer
              </Menu.Item>
              <Menu.Item key={'WareHouse'}>
                <Link to={'/warehouse'} />
                Ware House
              </Menu.Item>
            </SubMenu>

            <SubMenu key={'Master'} icon={<AdminPanelSettingsOutlined />} title={'Masters'}>

            {objMenu?.Client?.isTableView?
            
            <Menu.Item key={'Client'}>
                <Link to={'/client'} />
                Client
              </Menu.Item>
:''}


{objMenu?.Supplier?.isTableView?

<Menu.Item key={'Supplier'}>
                <Link to={'/suppliers'} />
                Supplier
              </Menu.Item>
              :''}

              
              <Menu.Item key={'Models'}>
                <Link to={'/models'} />
                Models
              </Menu.Item>

              {objMenu?.Contractor?.isTableView ?
              <Menu.Item key={'Contractors'}>
                <Link to={'/contractor'} />
                Contractors
              </Menu.Item>
              :''}
              {objMenu['Cash Accounts']?.isTableView? 
              <Menu.Item key={'CashAccount'}>
                <Link to={'/account/cash'} />
                Cash Account
              </Menu.Item>
              :''}

{objMenu['Bank Accounts']?.isTableView? 
<Menu.Item key={'BankAccount'}>
                <Link to={'/account/bank'} />
                Bank Account
              </Menu.Item>
              :''}

{objMenu['Other Accounts']?.isTableView? 
              <Menu.Item key={'OtherAccount'}>
                <Link to={'/account/other'} />
                Other Account
              </Menu.Item>
              :''}

{objMenu?.Materials?.isTableView? 
              <Menu.Item key={'Materials'}>
                <Link to={'/materials'} />
                Materials
              </Menu.Item>:'' }
              {
                objMenu?.Units?.isTableView?
                <Menu.Item key={'Unit'}>
                <Link to={'/unit'} />
                Units
              </Menu.Item>
              :'' }
              {
                objMenu?.Branch?.isTableView?
                <Menu.Item key={'Branch'}>
                <Link to={'/branch'} />
                Branch
              </Menu.Item>
             :'' }
                
              {objMenu?.Company ?
              <Menu.Item key={'Company'}>
                <Link to={'/company'} />
                Company
              </Menu.Item> :'' }

              {
                objMenu['All Type']?.isTableView?
                <Menu.Item key={'AllTypes'}>
                <Link to={'/all_types'} />
                All Types
              </Menu.Item>
             :'' }
            </SubMenu>

            <SubMenu key={'Settings'} icon={<SettingOutlined />} title={'Settings'}>

            {objMenu['User Permission']?.isTableView?
    <Menu.Item key={'UserManagement'}>
                <Link to={'/users'} />
                Users
              </Menu.Item>
               :''}

{objMenu['User Permission']?.isTableView?

              <Menu.Item key={'UserActivity'}>
                <Link to={'/useractivity'} />
                User Activity
              </Menu.Item>

              
:''}
              <Menu.Item key={'PrintSettings'}>
                <Link to={'/printSettings'} />
                Print Settings
              </Menu.Item>
              {objMenu['User Permission']?.isTableView?
              <Menu.Item key={'UserPermission'}>
                <Link to={'/user_permission'} />
                User Permission
              </Menu.Item>
              :''}
              <Menu.Item key={'GeneralSettings'}>
                <Link to={'/generalsettings'} />
                General Settings
              </Menu.Item>
            </SubMenu>

          </Menu>}
      </Sider>
    </>
  );
}

function MobileSidebar() {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  return (
    <>
      <Button type="text" size="large" onClick={showDrawer} className="mobile-sidebar-btn">
        <MenuOutlined />
      </Button>
      <Drawer
        width={200}
        placement="left"
        closable={false}
        onClose={onClose}
        visible={visible}
        className="mobile-sidebar-wraper"
      >
        <Sidebar collapsible={false} />
      </Drawer>
    </>
  );
}
