import React from 'react';
// import {
//   DesktopOutlined,
//   SettingOutlined,
//   CustomerServiceOutlined,
//   FileTextOutlined,
//   FileSyncOutlined,
//   DashboardOutlined,
//   TeamOutlined,
//   UserOutlined,
//   CreditCardOutlined,
//   BankOutlined,
// } from "@ant-design/icons";

// export const IconMenu = ({ name }) => {
//   const components = {
//     DesktopOutlined: DesktopOutlined,
//     SettingOutlined: SettingOutlined,
//     CustomerServiceOutlined: CustomerServiceOutlined,
//     FileTextOutlined: FileTextOutlined,
//     FileSyncOutlined: FileSyncOutlined,
//     DashboardOutlined: DashboardOutlined,
//     TeamOutlined: TeamOutlined,
//     UserOutlined: UserOutlined,
//     CreditCardOutlined: CreditCardOutlined,
//     BankOutlined: BankOutlined,
//     Default: DesktopOutlined,
//   };

//   const IconTag = components[name || "Default"] || SettingOutlined;
//   return <IconTag />;
// };

export const routesConfig = [
  {
    path: '/',
    component: 'Dashboard',
  },
  {
    path: '/customer',
    component: 'Customer',
  },
  {
    path: '/invoice',
    component: 'Invoice',
  },
  {
    path: '/quote',
    component: 'Quote',
  },
  {
    path: '/payment/invoice',
    component: 'PaymentInvoice',
  },
  {
    path: '/employee',
    component: 'Employee',
  },
  {
    path: '/admin',
    component: 'Admin',
  },
  {
    path: '/settings',
    component: 'Settings/Settings',
  },
  {
    path: '/payment/mode',
    component: 'PaymentMode',
  },
  {
    path: '/role',
    component: 'Role',
  },
  {
    path: '/branch',
    component: 'Branch',
  },
  {
    path: '/worktypes',
    component: 'WorkCategory',
  },
  {
    path: '/models',
    component: 'Model',
  },
  {
    path: '/unit',
    component: 'Unit',
  },
  {
    path: '/materials',
    component: 'Materials',
  },
  {
    path: '/suppliers',
    component: 'Suppliers',
  },
  {
    path: '/contractor',
    component: 'Contractors',
  },
  {
    path: '/journalentry',
    component: 'JournalEntry',
  },
  {
    path: '/purchase',
    component: 'Purchase',
  },{
    path: '/rental',
    component: 'RentalMaterial',
  },{
    path: '/account/cash',
    component: 'CashAccount',
  },{
    path: '/account/bank',
    component: 'BankAccount',
  },{
    path: '/client',
    component: 'Client',
  },{
    path: '/users',
    component: 'Users',
  },{
    path: '/project',
    component: 'Project',
  },
  {
    path: '/warehouse',
    component: 'WareHouse',
  },
   {
    path: '/taxes',
    component: 'TaxSettings',
  },
  {
    path: '/client/payment',
    component: 'ClientPayment',
  },
  {
    path: '/purchase_order',
    component: 'PurchaseOrder',
  },
  {
    path: '/expense_entry',
    component: 'Expenses'
  },
  {
    path: '/sub_contract',
    component: 'SubContract'
  },{
    path: '/account/other',
    component: 'OtherAccount'
  },{
    path: '/boq',
    component: 'Boq'
  },{
    path: '/transaction',
    component: 'Transaction'
  },
  {
    path: '/useractivity',
    component: 'UserActivity'
  },
  {
    path: '/user_permission',
    component: 'UserPermission'
  },
  {
    path: '/stock_items',
    component: 'StockItems'
  },
  {
    path: '/stock_transfer',
    component: 'StockTransfer'
  },
  {
    path: '/task_management',
    component: 'TaskManage'
  }
];
